<template>
  <div class="mobile-menu">
    <div class="mobile-menu-box">
      <div class="mobile-menu-box-img">
        <img src="https://tgjhimg.xiaoj.com/image/pc/logob.png" />
      </div>
      <div class="mobile-menu-box-menu">
        <Icon
          type="md-menu"
          @click="menuShow = true"
          size="35"
        />
        <Drawer
          title="超级号店"
          :closable="false"
          v-model="menuShow"
        >
          <p class="mobile-menu-box-menu-home">首页</p>
          <Divider />
          <div
            class="mobile-menu-box-menu-card"
            @click="handleHCard"
          >
            <div>
              号卡功能
            </div>
            <div>
              <Icon :type="hCardShow?'ios-arrow-down':'ios-arrow-up'" />
            </div>
          </div>
          <div>
            <Row
              type="flex"
              v-show="hCardShow"
            >
              <Col
                span="12"
                v-for="(item,index) in menuHCardList"
                :key="index"
              >
              {{ item }}
              </Col>
            </Row>
          </div>
          <Divider />
          <div
            class="mobile-menu-box-menu-card"
            @click="handlePlan"
          >
            <div>
              解决方案
            </div>
            <div>
              <Icon :type="planShow?'ios-arrow-down':'ios-arrow-up'" />
            </div>
          </div>
          <Row
            type="flex"
            v-show="planShow"
          >
            <Col
              v-for="(item,index) in menuPlanList"
              :key="index"
              span="12"
            >
            {{ item }}
            </Col>
          </Row>
          <Divider />
          <div class="mobile-menu-box-menu-card">
            <div>
              <div>
                客户案例
              </div>
            </div>
          </div>
          <div class="mobile-menu-box-menu-card">
            <div>
              <div>
                价格
              </div>
            </div>
          </div>
          <div class="mobile-menu-box-menu-card">
            <div>
              <div>
                更多服务
              </div>
            </div>
          </div>
          <div class="mobile-menu-box-menu-card">
            <div>
              <div>
                帮助
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileMenu',
  data () {
    return {
      menuShow: false,
      hCardShow: true,
      planShow: true,
      menuHCardList: [
        '产品商城', '通道对接', '号卡广场', '号卡分销', '佣金管理', '朋友圈素材',
        '多渠道', '多版本', '店铺装修', '号码共销', '代理商模式', '靓号盲盒', '优惠券',
        '秒杀', '拍卖', '助力', '会员营销', '礼品卡'
      ],
      menuPlanList: [
        '号卡售卖解决方案', '号卡分销解决方案', '靓号商城解决方案', '号卡电商企业',
        '号卡分销商', '私域流量主', '个人兼职'
      ]
    }
  },
  methods: {
    handleHCard () {
      this.hCardShow = !this.hCardShow
    },
    handlePlan () {
      this.planShow = !this.planShow
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-menu {
  .mobile-menu-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
    height: 3.2rem;
    border-bottom: 1px solid rgb(243, 243, 243);

    &-img {
      height: 3.125rem;
      margin-right: 5rem;
    }
  }
}
.mobile-menu-box-menu-home {
  font-weight: bold;
}
.ivu-divider-horizontal {
  margin: 0.625rem 0;
}
.mobile-menu-box-menu-card {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.ivu-row-flex {
  margin-top: 15px;
  font-size: 12px;
  color: #969799;
}
.ivu-col {
  padding-bottom: 15px;
}
</style>