<template>
  <div class="menu">
    <div class="menu-image">
      <img :src="scrollValue?imgbUrl:imgUrl" />
    </div>
    <div class="menu-list">
      <div class="list-item">
        <Poptip
          trigger="hover"
          placement="bottom"
          width="600"
        >
          <a
            href="javascript:void(0)"
            :style="{color: fontColor}"
          >
            功能
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <div
            class="api"
            slot="content"
          >
            <Row>
              <Col span="24">
              <div class="list-item-title">
                号卡功能
              </div>
              </Col>
              <Col span="24">
              <hr class="list-item-line">
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                产品商城
              </div>
              <div class="list-item-title-bottom">
                官方办卡通道任你挑
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                通道对接
              </div>
              <div class="list-item-title-bottom">
                技术帮你对接上下游通道
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                号卡广场
              </div>
              <div class="list-item-title-bottom">
                用户自助购买号卡
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                号卡分销
              </div>
              <div class="list-item-title-bottom">
                二级分销,号卡销售新模式
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                佣金管理
              </div>
              <div class="list-item-title-bottom">
                便捷管理分销、代理得佣金
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                朋友圈素材
              </div>
              <div class="list-item-title-bottom">
                定期分销素材，推广更方便
              </div>
              </Col>
              <Col :xs="24">
              <div class="list-item-title-ctitle">
                靓号商城
              </div>
              </Col>
              <Col span="24">
              <hr class="list-item-line">
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                多渠道
              </div>
              <div class="list-item-title-bottom">
                H5、公众号、小程序
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                多版本
              </div>
              <div class="list-item-title-bottom">
                资讯站、在线支付站、广告站
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                店铺装修
              </div>
              <div class="list-item-title-bottom">
                建站如搭积木一样简单
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                号码共销
              </div>
              <div class="list-item-title-bottom">
                高性能管理号码
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                代理商模式
              </div>
              <div class="list-item-title-bottom">
                二级分销+代理商模式
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                靓号盲盒
              </div>
              <div class="list-item-title-bottom">
                首创靓号盲盒,助力新增长
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                优惠券
              </div>
              <div class="list-item-title-bottom">
                满减券、代金券
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                秒杀
              </div>
              <div class="list-item-title-bottom">
                定期秒杀抢最靓的号
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                拍卖
              </div>
              <div class="list-item-title-bottom">
                定期拍卖抢靓号
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                助力
              </div>
              <div class="list-item-title-bottom">
                分享砍价,快速推广
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                会员营销
              </div>
              <div class="list-item-title-bottom">
                刺激老客户,吸引新客户
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                礼品卡
              </div>
              <div class="list-item-title-bottom">
                靓号送人,表白利器
              </div>
              </Col>
            </Row>
          </div>
        </Poptip>
      </div>
      <div class="list-item">
        <Poptip
          trigger="hover"
          placement="bottom"
          width="600"
        >
          <a
            href="javascript:void(0)"
            :style="{color: fontColor}"
          >
            解决方案
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <div
            class="api"
            slot="content"
          >
            <Row>
              <Col
                :xs="24"
                :md="24"
              >
              <div class="list-item-title">
                场景解决方案
              </div>
              </Col>
              <Col span="24">
              <hr class="list-item-line2">
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                号卡售卖解决方案
              </div>
              <div class="list-item-title-bottom">
                三大运营商号卡全方位营销
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                号卡分销解决方案
              </div>
              <div class="list-item-title-bottom">
                二级分销、引爆朋友圈蓝海
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                靓号商城解决方案
              </div>
              <div class="list-item-title-bottom">
                实现号码销售新增长
              </div>
              </Col>
              <Col
                :xs="24"
                :md="24"
              >
              <div class="list-item-title-ctitle">
                角色解决方案
              </div>
              </Col>
              <Col span="24">
              <hr class="list-item-line2">
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                号卡电商企业
              </div>
              <div class="list-item-title-bottom">
                打造数字化营销全流程管理
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                号卡分销商
              </div>
              <div class="list-item-title-bottom">
                接入通道,扩展销售渠道
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                私域流量主
              </div>
              <div class="list-item-title-bottom">
                流量变现蓝海
              </div>
              </Col>
              <Col
                :xs="24"
                :md="8"
                class="list-item-tabs"
              >
              <div class="list-item-title-top">
                个人兼职
              </div>
              <div class="list-item-title-bottom">
                0成本、0囤货、0风险副业创业
              </div>
              </Col>
            </Row>
          </div>
        </Poptip>
      </div>
      <div class="list-item">
        <a
          href="javascript:void(0)"
          :style="{color: fontColor}"
        >
          客户案例
        </a>
      </div>
      <div class="list-item">
        <a
          href="javascript:void(0)"
          :style="{color: fontColor}"
        >
          价格
        </a>
      </div>
      <div class="list-item">
        <a
          href="javascript:void(0)"
          :style="{color: fontColor}"
        >
          更多服务
        </a>
      </div>
      <div class="list-item">
        <a
          href="javascript:void(0)"
          :style="{color: fontColor}"
        >
          帮助
        </a>
      </div>
    </div>
    <div class="menu-login">
      <div class="btn-login">
        <Button
          ghost
          :style="{background: btnColor, borderColor: fontColor, color: fontColor}"
          to="https://lianghao880.com/saas/#/login"
        >
          登录
        </Button>
      </div>
      <div class="btn-free">
        <Button
          :type="scrollValue ? 'info' : 'default'"
          @click="handleCode"
        >免费试用</Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'menuItem',
  props: {
    fontColor: {
      type: String,
    },
    btnColor: {
      type: String
    },
    scrollValue: {
      type: Boolean
    }
    // btnBgColor: {
    //   type: String
    // }
  },
  data () {
    return {
      imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/logo.png',
      imgbUrl: 'https://tgjhimg.xiaoj.com/image/pc/logob.png'
    }
  },
  methods: {
    handleCode () {
      this.$emit('handleCode')
    },
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.menu {
  width: 1300px;
  margin: 0 auto;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  .menu-list {
    display: flex;
    justify-content: center;
    margin-left: 100px;

    .list-item {
      // width: 100px;
      margin-left: 50px;

      // .list-item-title {
      // }
      .list-item-tabs:hover {
        background: rgb(246, 246, 246);
        cursor: pointer;
      }

      .list-item-line {
        width: 50px;
        border: 1px solid rgb(85, 155, 255);
      }

      .list-item-line2 {
        width: 80px;
        border: 1px solid rgb(85, 155, 255);
      }

      .list-item-title-ctitle {
        padding-top: 30px;
      }

      .list-item-title-top {
        padding-top: 10px;
        font-size: 16px;
        color: black;
      }

      .list-item-title-bottom {
        font-size: 12px;
        color: rgb(168, 167, 167);
      }

      a {
        font-size: 16px;

        &:hover {
          color: #02a7f0 !important;
        }
      }
    }
  }

  .menu-login {
    margin-left: 200px;
    display: flex;

    .btn-login {
      .ivu-btn {
        &:hover {
          border-color: #02a7f0 !important;
          color: #02a7f0 !important;
        }
      }
    }
    .btn-free {
      margin-left: 10px;
      .ivu-btn {
        &:hover {
          border-color: #0282b9 !important;
          color: #0282b9 !important;
        }
      }
    }
  }
}

@media screen and(max-width: 720px) {
  .menu {
    display: none;
  }
}
</style>