<template>
  <div class="home-tabs">
    <div class="tabs-box">
      <div
        v-for="(item, index) in tabsList"
        :key="index"
      >
        <div
          class="tabs-item"
          :class="overShow == item.id ? 'on' : ''"
          @mouseover="mouseOver(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="tabs-list">
      <div class="tabs-list-box">
        <div class="list-img">
          <img :src="tabsListItem.imgUrl">
        </div>
        <div class="list-title">
          <div class="list-title-top">
            {{ tabsListItem.title }}
          </div>
          <div
            v-for="(item, index) in tabsListItem.items"
            :key="index"
          >
            <div class="list-title-ctitle">
              <div>
                <Icon
                  type="md-arrow-dropright"
                  size="30"
                  color="#02a7f0"
                />
              </div>
              <div class="ctitle-left">
                {{ item.lTitle }}
              </div>
              <div class="ctitle-right">
                {{ item.rTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'homeTabs',
  data () {
    return {
      overShow: 0,
      tabsList: [
        {
          id: 1,
          title: '超级裂变'
        },
        {
          id: 2,
          title: '上游通道对接'
        },
        {
          id: 3,
          title: '亿量号码资源'
        },
        {
          id: 4,
          title: '在线商城'
        },
        {
          id: 5,
          title: '营销利器'
        },
        {
          id: 6,
          title: '管理可视化'
        }
      ],
      tabsListItem: {
        imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/img_fission.png',
        title: '分销裂变让会员帮你卖，让你好友帮你卖，全国3000+卡商帮你卖',
        items: [
          {
            lTitle: '社交裂变:',
            rTitle: '二级分销系统、代理商系统，24小时不间断自动获客'
          },
          {
            lTitle: '模式多样:',
            rTitle: '售价抽成、上游佣金返佣，多种返佣模式任意配置'
          },
          {
            lTitle: '透明结算:',
            rTitle: '清晰透明的订单明细，可按天/月统计结算'
          },
          {
            lTitle: '高效管理:',
            rTitle: '推广员、代理商管理、统计功能，高效掌控业务拓展'
          }
        ]
      }
    }
  },
  methods: {
    mouseOver (item) {
      this.overShow = item.id
      if (this.overShow == 1) {
        this.tabsListItem.title = '分销裂变让会员帮你卖，让你好友帮你卖，全国3000+卡商帮你卖'
        this.tabsListItem.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_fission.png'
        this.tabsListItem.items = [
          {
            lTitle: '社交裂变:',
            rTitle: '二级分销系统、代理商系统，24小时不间断自动获客'
          },
          {
            lTitle: '模式多样:',
            rTitle: '售价抽成、上游佣金返佣，多种返佣模式任意配置'
          },
          {
            lTitle: '透明结算:',
            rTitle: '清晰透明的订单明细，可按天/月统计结算'
          },
          {
            lTitle: '高效管理:',
            rTitle: '推广员、代理商管理、统计功能，高效掌控业务拓展'
          }
        ]
      } else if (this.overShow == 2) {
        this.tabsListItem.title = '利用技术手段对接开卡链接，用户线上下单即可直提至上游通道'
        this.tabsListItem.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Docking.png'
        this.tabsListItem.items = [
          {
            lTitle: '技术对接:',
            rTitle: '利用模拟、手动等方式对接通道的开卡链接'
          },
          {
            lTitle: '页面美化:',
            rTitle: '可设计特色的号卡办理宣传页面，提高转化率'
          },
          {
            lTitle: '订单同步:',
            rTitle: '实时同步更新订单状态，及时了解订单情况'
          },
          {
            lTitle: '佣金管理:',
            rTitle: '一键导入佣金单即可自动给分销员发放返佣，轻松不出错'
          }
        ]
      } else if (this.overShow == 3) {
        this.tabsListItem.title = '靓号普号、号卡渠道全平台共享共销，不用采购、不用囤货，直接用'
        this.tabsListItem.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_resources.png'
        this.tabsListItem.items = [
          {
            lTitle: '千万靓号库:',
            rTitle: '全国最大的靓号库可供您最低代理价销售'
          },
          {
            lTitle: '商家号码库:',
            rTitle: '自建号码库，您可自销也可以供他人分销'
          },
          {
            lTitle: '平台渠道:',
            rTitle: '官方数十个开卡渠道任你挑选，如王卡、花卡'
          }
        ]
      } else if (this.overShow == 4) {
        this.tabsListItem.title = '功能强大的靓号商城，快速实现号码生意线上化、信息化'
        this.tabsListItem.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Mall.png'
        this.tabsListItem.items = [
          {
            lTitle: '快速搜号:',
            rTitle: '强大的底层架构，支持上亿量级，做到多维度、秒级搜号'
          },
          {
            lTitle: '自定义装修:',
            rTitle: '商城可自定义每一个页面，建站和搭积木一样简单'
          },
          {
            lTitle: '多版本选择:',
            rTitle: '可建咨询站、在线支付站、广告专用站等，适配不同场景'
          },
          {
            lTitle: '多渠道:',
            rTitle: '可生成H5、公众号、小程序靓号商城'
          }
        ]
      } else if (this.overShow == 5) {
        this.tabsListItem.title = '集成当下最常用的营销活动，打通销售全流程，拉新、复购全搞定'
        this.tabsListItem.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Marketing.png'
        this.tabsListItem.items = [
          {
            lTitle: '活动营销:',
            rTitle: '优惠券、秒杀、拍卖等营销活动随心选'
          },
          {
            lTitle: '靓号盲盒:',
            rTitle: '首创靓号盲盒营销模式，提升用户的购买力'
          },
          {
            lTitle: '会员营销:',
            rTitle: '打造云集、贝店模式，实现流量及复购的裂变式增长'
          },
          {
            lTitle: '客户管理:',
            rTitle: '高效的客户CRM功能，将客户资料最大化利用'
          }
        ]
      } else {
        this.tabsListItem.title = '业务全流程系统处理，提升效率，配备数据分析做到决策有据可循'
        this.tabsListItem.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Administration.png'
        this.tabsListItem.items = [
          {
            lTitle: '管理功能:',
            rTitle: '最适合号码行业的号码管理、订单管理，让工作效率提升数倍'
          },
          {
            lTitle: '用户分析:',
            rTitle: '对接腾讯、百度统计，深入分析用户画像，行为轨迹'
          },
          {
            lTitle: '销售分析:',
            rTitle: '商品、订单、用户等多维度统计分析，提供商业智能'
          },
          {
            lTitle: '多端支持:',
            rTitle: '支持电脑端、移动端使用，随时随地管理系统'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-tabs {
  .tabs-box {
    display: flex;
    align-items: center;
    justify-content: center;

    .tabs-item {
      font-size: 16px;
      width: 150px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border: 1px solid rgba(215, 215, 215, 1);

      // &:hover {
      //   border-color: #02a7f0;
      //   color: #02a7f0;
      // }

      &.on {
        border-color: #02a7f0;
        color: #02a7f0;
      }
    }
  }
  .tabs-list {
    display: flex;
    justify-content: center;
    align-items: center;

    .tabs-list-box {
      width: 900px;
      display: flex;

      .list-img {
        width: 432px;
        height: 357px;
        padding-top: 30px;

        img {
          margin-top: 10px;
          max-width: 100%;
          vertical-align: middle;
          // height: 100%;
          max-height: 100%;
        }
      }

      .list-title {
        height: 357px;
        width: 550px;

        .list-title-top {
          margin-top: 50px;
          color: #555;
          font-size: 16px;
        }
        .list-title-ctitle {
          display: flex;
          align-items: center;
          margin-top: 30px;
          margin-left: 10px;

          .ctitle-left {
            font-weight: 500;
            font-size: 16px;
          }

          .ctitle-right {
            padding-left: 10px;
            color: #7f7f7f;
          }
        }
      }
    }
  }
}

@media screen and(max-width: 720px) {
  .home-tabs {
    .tabs-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: wrap;
    }
    .tabs-list {
      display: block;

      .tabs-list-box {
        display: block;

        .list-img {
          padding: 0 10px;
        }

        .list-title {
          height: 357px;

          .list-title-top {
            width: 75%;
            min-width: 75%;
            max-width: 75%;
            margin-top: 10px;
            margin-left: 30px;
            padding-right: 50px;
            color: #555;
            font-size: 16px;
          }
          .list-title-ctitle {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 30px;
            margin-left: 10px;

            .ctitle-left {
              width: 20%;
              min-width: 20%;
              max-width: 20%;
              font-weight: 500;
              font-size: 16px;
            }

            .ctitle-right {
              width: 75%;
              min-width: 75%;
              max-width: 75%;
              padding-left: 10px;
              padding-right: 50px;
              color: #7f7f7f;
            }
          }
        }
      }
    }
  }
}
</style>