import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import MetaInfo from 'vue-meta-info';

Vue.config.productionTip = false;
Vue.use(ViewUI);
Vue.use(MetaInfo);

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    console.log('seo update');
  },
}).$mount('#app');
