<template>
  <div class="mobile-tabs">
    <div class="mobile-tabs-title">
      <h2>
        超级号店，号卡数字化营销解决方案专家
      </h2>
      <!-- <h3>
        深度融合
      </h3>
      <h4>
        号卡渠道、靓号货源、 号卡商城、号卡分销、订单追踪、客户管理、数据分析
      </h4>
      <h3>
        ,智能驱动每一个销售环节
      </h3> -->
    </div>
    <div class="mobile-tabs-btnbox">
      <div
        v-for="(item, index) in tabsMenuList"
        :key="index"
      >
        <div
          class="mobile-tabs-btnbox-item"
          :class="overShow == item.id ? 'on' : ''"
          @mouseover="mouseOver(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="mobile-tabs-scroll">
      <div class="mobile-tabs-scroll-list">
        <div class="mobile-tabs-scroll-list-card">
          <div class="mobile-tabs-scroll-list-card-img">
            <img :src="tabsList.imgUrl">
          </div>
          <!-- <div
            class="mobile-tabs-scroll-list-card-title"
            v-for="(ite,index) in tabsList.items"
            :key="index"
          >
            <div class="mobile-tabs-scroll-list-card-title-hbox">
              <Icon
                type="md-arrow-dropright"
                size="10"
                color="#02a7f0"
              />
              <h2>
                {{ ite.lTitle }}
              </h2>
            </div>

            <h3>
              {{ ite.rTitle }}
            </h3>
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'mobileTabs',
  data () {
    return {
      overShow: 0,
      tabsList: {
        imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/img_fission.png',
        title: '分销列表让会员帮你卖，让你好友帮你卖，全国3000+卡商帮你卖',
        items: [
          {
            lTitle: '社交裂变:',
            rTitle: '二级分销系统、代理商系统，24小时不间断自动获客'
          },
          {
            lTitle: '模式多样:',
            rTitle: '售价抽成、上游佣金返佣，多种返佣模式任意配置'
          },
          {
            lTitle: '透明结算:',
            rTitle: '清晰透明的订单明细，可按天/月统计结算'
          },
          {
            lTitle: '高效管理:',
            rTitle: '推广员、代理商管理、统计功能，高效掌控业务拓展'
          }
        ]

      },
      tabsMenuList: [
        {
          id: 1,
          title: '超级裂变'
        },
        {
          id: 2,
          title: '上游通道对接'
        },
        {
          id: 3,
          title: '亿量号码资源'
        },
        {
          id: 4,
          title: '在线商城'
        },
        {
          id: 5,
          title: '营销利器'
        },
        {
          id: 6,
          title: '管理可视化'
        }
      ],
    }
  },
  mounted () {
  },
  methods: {
    mouseOver (item) {
      // console.log(this.tabsList)
      this.overShow = item.id
      if (this.overShow == 1) {
        this.tabsList.title = '分销列表让会员帮你卖，让你好友帮你卖，全国3000+卡商帮你卖'
        this.tabsList.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_fission.png'
        this.tabsList.items = [
          {
            lTitle: '社交裂变:',
            rTitle: '二级分销系统、代理商系统，24小时不间断自动获客'
          },
          {
            lTitle: '模式多样:',
            rTitle: '售价抽成、上游佣金返佣，多种返佣模式任意配置'
          },
          {
            lTitle: '透明结算:',
            rTitle: '清晰透明的订单明细，可按天/月统计结算'
          },
          {
            lTitle: '高效管理:',
            rTitle: '推广员、代理商管理、统计功能，高效掌控业务拓展'
          }
        ]
      } else if (this.overShow == 2) {
        this.tabsList.title = '利用技术手段对接开卡链接，用户线上下单即可直提至上游通道'
        this.tabsList.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Docking.png'
        this.tabsList.items = [
          {
            lTitle: '技术对接:',
            rTitle: '利用破解、模拟、手动等方式对接通道的开卡链接'
          },
          {
            lTitle: '页面美化:',
            rTitle: '可设计特色的号卡办理宣传页面，提高转化率'
          },
          {
            lTitle: '订单同步:',
            rTitle: '实时同步更新订单状态，及时了解订单情况'
          },
          {
            lTitle: '佣金管理:',
            rTitle: '一键导入佣金单即可自动给分销员发放返佣，轻松不出错'
          }
        ]
      } else if (this.overShow == 3) {
        this.tabsList.title = '靓号普号、号卡渠道全平台共享共销，不用采购、不用囤货，直接用'
        this.tabsList.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_resources.png'
        this.tabsList.items = [
          {
            lTitle: '千万靓号库:',
            rTitle: '全国最大的靓号库可供您最低代理价销售'
          },
          {
            lTitle: '商家号码库:',
            rTitle: '自建号码库，您可自销也可以供他人分销'
          },
          {
            lTitle: '平台渠道:',
            rTitle: '官方数十个开卡渠道任你挑选，如王卡、花卡'
          }
        ]
      } else if (this.overShow == 4) {
        this.tabsList.title = '功能强大的靓号商城，快速实现号码生意线上化、信息化'
        this.tabsList.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Mall.png'
        this.tabsList.items = [
          {
            lTitle: '快速搜号:',
            rTitle: '强大的底层架构，支持上亿量级，做到多维度、秒级搜号'
          },
          {
            lTitle: '自定义装修:',
            rTitle: '商城可自定义每一个页面，建站和搭积木一样简单'
          },
          {
            lTitle: '多版本选择:',
            rTitle: '可建咨询站、在线支付站、广告专用站等，适配不同场景'
          },
          {
            lTitle: '多渠道:',
            rTitle: '可生成H5、公众号、小程序靓号商城'
          }
        ]
      } else if (this.overShow == 5) {
        this.tabsList.title = '集成当下最常用的营销活动，打通销售全流程，拉新、复购全搞定'
        this.tabsList.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Marketing.png'
        this.tabsList.items = [
          {
            lTitle: '活动营销:',
            rTitle: '优惠券、秒杀、拍卖等营销活动随心选'
          },
          {
            lTitle: '靓号盲盒:',
            rTitle: '首创靓号盲盒营销模式，提升用户的购买力'
          },
          {
            lTitle: '会员营销:',
            rTitle: '打造云集、贝店模式，实现流量及复购的裂变式增长'
          },
          {
            lTitle: '客户管理:',
            rTitle: '高效的客户CRM功能，将客户资料最大化利用'
          }
        ]
      } else {
        this.tabsList.title = '业务全流程系统处理，提升效率，配备数据分析做到决策有据可循'
        this.tabsList.imgUrl = 'https://tgjhimg.xiaoj.com/image/pc/img_Administration.png'
        this.tabsList.items = [
          {
            lTitle: '管理功能:',
            rTitle: '最适合号码行业的号码管理、订单管理，让工作效率提升数倍'
          },
          {
            lTitle: '用户分析:',
            rTitle: '对接腾讯、百度统计，深入分析用户画像，行为轨迹'
          },
          {
            lTitle: '销售分析:',
            rTitle: '商品、订单、用户等多维度统计分析，提供商业智能'
          },
          {
            lTitle: '多端支持:',
            rTitle: '支持电脑端、移动端使用，随时随地管理系统'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-tabs {
  padding-top: 3rem;
  .mobile-tabs-title {
    text-align: center;
    padding: 0.3rem;
    padding-bottom: 1rem;
    h2 {
      font-size: 1.1rem;
      line-height: 1.1rem;
      font-weight: bold;
    }
    h3 {
      display: inline;
      padding-top: 0.5rem;
      font-size: 0.75rem;
      // color: #9c9eae;
      // line-height: 1.3rem;
      font-weight: 400;
    }
    h4 {
      display: inline;
      padding-top: 0.5rem;
      font-size: 0.75rem;
      font-weight: 400;
      color: #02a7f0;
    }
  }
  .mobile-tabs-scroll {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    .mobile-tabs-scroll-list {
      display: flex;
      justify-content: center;
      padding-left: 1.5rem;
      .mobile-tabs-scroll-list-card {
        width: 10rem;
        .mobile-tabs-scroll-list-card-img {
          text-align: center;
          height: 15rem;
          width: 20rem;
          img {
            max-height: 100%;
            text-align: center;
            vertical-align: middle;
          }
        }
        .mobile-tabs-scroll-list-card-title {
          width: 20rem;
          .mobile-tabs-scroll-list-card-title-hbox {
            display: flex;
            align-items: center;
            h2 {
              width: 10rem;
              font-weight: bold;
              font-size: 0.9rem;
            }
          }

          h3 {
            max-width: 20rem;
            font-size: 0.75rem;
            color: #7f7f7f;
          }
        }
      }
    }
  }
  .mobile-tabs-btnbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
    .mobile-tabs-btnbox-item {
      margin: 0.3rem;
      font-size: 0.9rem;
      width: 6.25rem;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border: 1px solid rgba(215, 215, 215, 1);
      &.on {
        border-color: #02a7f0;
        color: #02a7f0;
      }
    }
  }
}
</style>