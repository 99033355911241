<template>
  <div class="mobile-footer">
    <!-- <div
      v-for="(item, index) in footerList"
      :key="index"
    >
      <a href="JavaScript:void(0)">
        {{ item.title }}
      </a>
      <template v-for="(item, key) in item.children">
        <nav
          :key="key"
          v-if="item"
        >
          <a href="">
            {{ item }}
          </a>
        </nav>
      </template>
    </div> -->
    <!-- <div
      v-for="(item, index) in footerQWList"
      :key="'qw'+index"
      class="mobile-footer-authority"
    >
      <a href="JavaScript:void(0)">
        {{ item.title }}
      </a>
      <template v-for="(item,index) in item.children">
        <a
          href="JavaScript:void(0)"
          :key="index"
          v-if="item"
        >
          <img
            :src="item.icon"
            alt=""
          >
          <div>
            {{ item.title }}
          </div>
        </a>
      </template>
    </div> -->
    <div class="mobile-footer-code">
      <div class="mobile-footer-code-left">
        <img src="https://tgjhimg.xiaoj.com/image/pc/icon_qiye.png">
        <a href="JavaScript:void(0)">
          微信扫码联系
        </a>
        <a href="JavaScript:void(0)">
          商务合作、渠道合作
        </a>
      </div>
      <div class="mobile-footer-code-right">
        <img src="https://tgjhimg.xiaoj.com/image/pc/icon_gongzhong.jpg" />
        <a href="JavaScript:void(0)">微信扫码关注</a>
        <a href="JavaScript:void(0)">获取号卡动态</a>
      </div>
    </div>
    <hr />
    <div class="footer-copyright">
      Copyright © 2020 福州宽腾网络科技有限公司 | <a
        href="https://beian.miit.gov.cn"
        target="_blank"
      >闽ICP备17018560号-4</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileFooter',
  data () {
    return {
      footerList: [
        {
          title: '解决方案',
          children: [
            '号卡商城',
            '号卡分销',
            '靓号商城',
            '社交电商',
            '更多'
          ]
        },
        {
          title: '更多服务',
          children: [
            '定制私有化',
            '上下游通道对接',
            '互联网定制开发',
            '区块链定制开发'
          ]
        },
        {
          title: '帮助',
          children: [
            '帮助中心',
            '运营技巧',
            '行业新闻'
          ]
        }
      ],
      footerQWList: [
        {
          title: '权威认证',
          children: [
            {
              icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_gaoxin.png',
              title: '省级国家级高新技术企业高价认证'
            },
            {
              icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_kunpeng.png',
              title: '华为云容鲲鹏云认证技术认证'
            },
            {
              icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_lingyun.png',
              title: '华为云解决方案伙伴凌云计划'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-footer {
  width: 100%;
  background: #02204b;
  padding: 1rem;
  padding-top: 2.5rem;
  a {
    color: white;
  }
  div {
    a {
      display: block;
      font-size: 0.875rem;
    }
  }
  nav {
    display: inline;
    padding-top: 0.5rem;
    padding-right: 0.8rem;
    a {
      display: inline;
      font-size: 0.75rem;
    }
  }
  .mobile-footer-authority {
    a {
      display: flex;
      align-items: center;
      padding-top: 0.5rem;
      img {
        width: 2.5rem;
      }
      div {
        padding-left: 0.5rem;
        font-size: 0.75rem;
      }
    }
  }
  .mobile-footer-code {
    display: flex;
    justify-content: space-around;
    img {
      width: 5.9375rem;
    }
  }
  hr {
    width: 100%;
    margin: 0 auto;
    border-color: #797878;
    opacity: 0.6;
  }
  .footer-copyright {
    padding-top: 1rem;
    color: rgb(228, 226, 226);
    text-align: center;
    font-size: 0.75rem;
    a {
      color: rgb(228, 226, 226);
    }
  }
}
</style>