<template>
  <div class="mobile-service">
    <div class="mobile-service-title">
      <h2>服务保障</h2>
      <!-- <h3>选择超级号店,高于行业，先于时代</h3> -->
    </div>
    <div class="mobile-service-scroll">
      <div
        class="mobile-service-scroll-list"
        v-for="(item,index) in helpList"
        :key="index"
      >
        <div class="mobile-service-scroll-list-box">
          <div class="mobile-service-scroll-list-box-img">
            <img :src="item.imgUrl">
          </div>
          <div class="mobile-service-scroll-list-box-title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileService',
  data () {
    return {
      helpList: [
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_development.png',
          title: '研发资历成熟，系统由前百度产品及技术架构师打造，久经考验值得信赖。'
        },
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_cooperation .png',
          title: '公司及合作伙伴拥有7年号卡行业积累，接触号卡售卖市场，更了解号卡生意。'
        },
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_application .png',
          title: 'SAAS企业级应用，让更多人低成本、低消耗做号卡生意，提升业绩、流量变现。'
        },
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_service.png',
          title: '提供一站式服务，协助解决开店相关的公众号、小程序、支付等问题。'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-service {
  background: rgb(246, 247, 249);
  padding-bottom: 3rem;
  .mobile-service-title {
    padding-top: 3.5rem;
    text-align: center;
    h2 {
      font-size: 1.1rem;
      line-height: 1.1rem;
      font-weight: 600px;
      padding-bottom: 0.8rem;
    }
    h3 {
      padding-top: 0.5rem;
      font-size: 0.75rem;
      color: #9c9eae;
      line-height: 1.3rem;
      font-weight: 400;
    }
  }
  .mobile-service-scroll {
    display: flex;
    overflow-x: scroll;
    margin-top: 1rem;

    .mobile-service-scroll-list {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      .mobile-service-scroll-list-box {
        width: 9.75rem;
        height: 9.5rem;
        background: white;
        margin: 0.8rem;
        .mobile-service-scroll-list-box-img {
          width: 3.375rem;
          height: 3.75rem;
          text-align: center;
          margin: 0 auto;
          margin-top: -1.25rem;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .mobile-service-scroll-list-box-title {
          padding: 0.8rem;
          font-size: 0.8rem;
          text-align: center;
        }
      }
    }
  }
}
</style>