<template>
  <div class="home">
    <Spin fix v-if="spinShow"></Spin>
    <template v-else>
      <div class="home-top">
        <div class="top-carousel-box">
          <Carousel v-model="carouselValue" loop :autoplay="true" :autoplay-speed="5000">
            <CarouselItem>
              <div class="top-carousel">
                <div class="carousel-item">
                  <div class="item1">
                    实现号卡生意“管理+营销”全流程
                  </div>
                  <div class="item-box">
                    <div class="item2">
                      超级号店，帮助每一个企业做好号码生意
                    </div>
                    <div class="item2">
                      选择我们，高于行业，先于时代
                    </div>
                  </div>
                  <div class="item-btn">
                    <Button @click="handleCode()">
                      申请免费试用
                    </Button>
                  </div>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="top-carousel2">
                <div class="carousel-item">
                  <div class="item1">
                    共建号码“共享共销”新模式
                  </div>
                  <div class="item-box">
                    <div class="item2">
                      拓宽销售渠道，全员帮你卖号
                    </div>
                    <div class="item2">
                      有流量无货源也能卖号
                    </div>
                  </div>
                  <div class="item-btn">
                    <Button @click="handleCode()">
                      申请免费试用
                    </Button>
                  </div>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="top-carousel3">
                <div class="carousel-item">
                  <div class="item1">
                    社交电商号卡系统
                  </div>
                  <div class="item-box">
                    <div class="item2">
                      分享引导、自动裂变，营销利器
                    </div>
                    <div class="item2">
                      不断提升成单转化率和复购留存力
                    </div>
                  </div>
                  <div class="item-btn">
                    <Button @click="handleCode()">
                      申请免费试用
                    </Button>
                  </div>
                </div>
              </div>
            </CarouselItem>
          </Carousel>
        </div>
        <div :class="scrollValue == true ? 'isfixed' : 'top-menu'" v-show="!scrollValue">
          <menu-item @handleCode="handleCode" :fontColor="fontColor" :btnColor="btnColor" :scrollValue="scrollValue" />
        </div>
        <transition name="code">
          <div :class="scrollValue == true ? 'isfixed' : 'top-menu'" v-show="scrollValue">
            <menu-item @handleCode="handleCode" :fontColor="fontColor" :btnColor="btnColor" :scrollValue="scrollValue" />
          </div>
        </transition>
      </div>
      <div class="center-title">
        <div class="title-item-box">
          <div class="title-item">
            <div>
              <span class="icon1"> </span>
            </div>
            <div class="item-font">
              <div class="font-title">
                号卡售卖系统
              </div>
              <div class="font-text">
                三大运营商号卡全方位营销
              </div>
            </div>
          </div>
          <div class="title-item">
            <div>
              <span class="icon2"></span>
            </div>
            <div class="item-font">
              <div class="font-title">
                号卡分销系统
              </div>
              <div class="font-text">
                二级分销，引爆朋友圈蓝海
              </div>
            </div>
          </div>
          <div class="title-item">
            <div>
              <span class="icon3"></span>
            </div>
            <div class="item-font">
              <div class="font-title">
                靓号商城系统
              </div>
              <div class="font-text">
                高效低成本实现号码销售新增长
              </div>
            </div>
          </div>
        </div>
        <div class="title-super">
          超级号店，号卡数字化营销解决方案专家
        </div>
        <div class="title-describe">
          <span>
            深度融合
          </span>
          <span>
            号卡渠道、靓号货源、 号卡商城、号卡分销、订单追踪、客户管理、数据分析
          </span>
          <span>
            ,智能驱动每一个销售环节
          </span>
        </div>
      </div>
      <div class="home-tab">
        <home-tabs />
      </div>
      <div class="home-info">
        <div class="info-title">
          多场景使用，一切以增长为目的
        </div>
        <div class="info-more">
          <div
            class="more-top"
            v-for="(item, index) in moreList"
            :key="index"
            @mouseenter="mouseOver(index)"
            @mouseleave="mouseOver(index)"
          >
            <!-- @mouseover="mouseOver(index)"
            @mouseleave="mouseOver(index)" -->
            <!-- <div class="top-title">
              {{ item.title }}
            </div>
            <div class="top-line">
              <img src="https://tgjhimg.xiaoj.com/image/pc/img_line.png">
            </div> -->
            <div class="more-top-diamond"></div>
            <div class="more-top-diamond2"></div>
            <div class="more-bottom">
              <div class="bottom-img">
                <!-- {{ item.isShow}} -->
                <img :src="item.isShow ? item.icon : item.iconb" />
              </div>
              <div class="bottom-title">
                {{ item.info }}
              </div>
            </div>
          </div>
        </div>
        <div class="info-bottom">
          <Button ghost class="bottom-btn" @click="handleCode">
            申请免费试用
          </Button>
        </div>
      </div>
      <div class="home-service">
        <div class="service-title">
          <div class="title-top">
            服务保障
          </div>
          <div class="title-bottom">
            选择超级号店,高于行业，先于时代
          </div>
        </div>
        <div class="service-help">
          <Card dis-hover v-for="(item, index) in helpLisp" :key="index" class="help-box">
            <div class="help-box-img">
              <img :src="item.imgUrl" />
            </div>
            <div class="help-box-title">
              {{ item.title }}
            </div>
          </Card>
        </div>
      </div>
      <div class="home-voice">
        <div class="voice-title">
          <div class="voice-title-top">
            来自客户的心声
          </div>
          <div class="voice-title-bottom">
            3000+卡商已经行动起来了，未来可覆盖不同行业的自媒体人、UP主、直播主
          </div>
        </div>
        <div class="voice-carousel-box">
          <Carousel
            v-model="carouseVoice"
            loop
            :autoplay="true"
            :autoplay-speed="5000"
            dots="none"
            class="voice-title-carousel"
          >
            <CarouselItem>
              <div class="voice-carousel">
                <div class="voice-carousel-img">
                  <img src="https://tgjhimg.xiaoj.com/image/pc/icon_li.png" />
                </div>
                <div class="voice-carousel-detail">
                  <span class="detail-left">
                    李总
                  </span>
                  <span class="detail-right">
                    小巨网络小巨靓号项目总经理
                  </span>
                  <div class="detail-text">
                    已经用超级号店的号码电商系统7年了，从粗放的号码管理到现在的丰富功能，一直为公司创造更大的价值保驾护航。点赞！
                  </div>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="demo-carousel">
                <div class="voice-carousel">
                  <div class="voice-carousel-img">
                    <img src="https://tgjhimg.xiaoj.com/image/pc/icon_lin.png" />
                  </div>
                  <div class="voice-carousel-detail">
                    <span class="detail-left">
                      林总
                    </span>
                    <span class="detail-right">
                      XX靓号总经理
                    </span>
                    <div class="detail-text">
                      超级号店利用技术手段帮公司对接了移动花卡、王卡、宝藏卡等通道，同时我们也实现了号卡销售全面实现了线上化，销售提升了，管理也更方便了。
                    </div>
                  </div>
                </div>
              </div>
            </CarouselItem>
          </Carousel>
        </div>
      </div>
      <div class="home-lgo">
        <div class="home-lgo-title">
          让我们一起,进入号卡新营销时代
        </div>
        <Button class="home-lgo-button" shape="circle" @click="handleCode">
          申请免费试用
        </Button>
      </div>
      <div class="home-footer">
        <div class="footer-left">
          <dl>
            <dt>
              <a href="">
                解决方案
              </a>
            </dt>
            <dd>
              <a href="">
                号卡商城
              </a>
            </dd>
            <dd>
              <a href="">
                号卡分销
              </a>
            </dd>
            <dd>
              <a href="">
                靓号商城
              </a>
            </dd>
            <dd>
              <a href="">
                社交电商
              </a>
            </dd>
            <dd>
              <a href="">
                更多
              </a>
            </dd>
          </dl>
          <dl>
            <dt>
              <a href="">
                更多服务
              </a>
            </dt>
            <dd>
              <a href="">
                定制私有化
              </a>
            </dd>
            <dd>
              <a href="">
                上下游通道对接
              </a>
            </dd>
            <dd>
              <a href="">
                互联网定制开发
              </a>
            </dd>
            <dd>
              <a href="">
                区块链定制开发
              </a>
            </dd>
          </dl>
          <dl>
            <dt>
              <a href="">
                帮助
              </a>
            </dt>
            <dd>
              <a href="">
                帮助中心
              </a>
            </dd>
            <dd>
              <a href="">
                运营技巧
              </a>
            </dd>
            <dd>
              <a href="">
                行业新闻
              </a>
            </dd>
          </dl>
        </div>
        <div class="footer-content">
          <dl>
            <dt>
              <a href="">
                权威认证
              </a>
            </dt>
            <dd>
              <a href="">
                <div class="footer-content-img">
                  <img src="https://tgjhimg.xiaoj.com/image/pc/icon_gaoxin.png" />
                </div>

                <div>
                  <div>
                    省级国家级高新技术
                  </div>
                  <div>
                    企业高新认证
                  </div>
                </div>
              </a>
            </dd>
            <dd>
              <a href="">
                <div class="footer-content-img">
                  <img src="https://tgjhimg.xiaoj.com/image/pc/icon_kunpeng.png" />
                </div>
                <div>
                  <div>
                    华为云容鲲鹏云
                  </div>
                  <div>
                    认证技术认证
                  </div>
                </div>
              </a>
            </dd>
            <dd>
              <a href="">
                <div class="footer-content-img">
                  <img src="https://tgjhimg.xiaoj.com/image/pc/icon_lingyun.png" />
                </div>
                <div>
                  <div>
                    华为云解决方案
                  </div>
                  <div>
                    伙伴凌云计划
                  </div>
                </div>
              </a>
            </dd>
          </dl>
        </div>
        <div class="footer-right">
          <div class="right-imgbox">
            <img src="https://tgjhimg.xiaoj.com/image/pc/icon_qiye2.png" />
            <div>
              微信扫码联系
            </div>
            <div>
              商务合作、渠道合作
            </div>
          </div>
          <div class="right-imgbox">
            <img src="https://tgjhimg.xiaoj.com/image/pc/icon_gongzhong.jpg" />
            <div>
              微信扫码关注
            </div>
            <div>
              获取号卡动态
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <hr />
        <div class="footer-copyright-font">
          <span>
            福州宽腾网络科技有限公司
          </span>
          <span class="font-right">
            Copyright © 2020 福州宽腾网络科技有限公司 |
            <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备17018560号-4</a>
          </span>
        </div>
      </div>
      <div class="home-float">
        <div class="float-backtop">
          <Affix :offset-bottom="250">
            <div class="float-customer-box" @click="handleCode">
              <img src="https://tgjhimg.xiaoj.com/image/pc/icon_customer.png" />
            </div>
          </Affix>
        </div>
        <div class="float-backtop">
          <BackTop :height="100" :bottom="100">
            <div class="float-backtop-box">
              <img src="https://tgjhimg.xiaoj.com/image/pc/icon_arrow.png" />
            </div>
          </BackTop>
        </div>
      </div>
      <transition name="codeimgbox">
        <div class="code-mask" v-show="codeShow" @click="closeCode($event)">
          <transition name="codeimg">
            <div class="home-code" ref="codeSh" v-show="codeShow">
              <div class="code-img">
                <img src="https://tgjhimg.xiaoj.com/image/pc/code_yxiao.png" />
              </div>
              <div class="code-close">
                <Icon type="md-close-circle" @click="handleCode" size="50" color="white" />
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import menuItem from './homeUnit/menuItem';
import homeTabs from './homeUnit/tabs';
export default {
  name: 'Home',
  components: {
    menuItem,
    homeTabs,
  },
  metaInfo: {
    title: '超级号店',
    meta: [
      {
        name: 'keyword',
        content:
          '靓号销售平台,手机靓号交易,定制号码购买,企业靓号服务,号码拍卖系统,高端号码市场,号码资源管理,虚拟号码服务,号码交易安全,号码选购指南',
      },
      {
        name: 'description',
        content:
          '超级号店 - 您的专业靓号销售SaaS平台。我们提供全面的手机靓号交易服务，包括定制号码购买、企业靓号解决方案和安全高效的号码拍卖系统。加入我们，享受高端号码市场的便利与安全，让您的通讯标识更加独特和专业。',
      },
    ],
  },
  data() {
    return {
      spinShow: true,
      carouselValue: 0, // 轮播图起点
      carouseVoice: 0,
      codeShow: false,
      scrollValue: false,
      fontColor: '#ffffff',
      btnColor: '',
      moreList: [
        {
          isShow: true,
          title: '免安装，免部署使用超级号店快速构建号卡销售系统，实现运营数字化、营销自动化、管理精细化',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_retailers@2x.png',
          iconb: 'https://tgjhimg.xiaoj.com/image/pc/icon_retailers_b@2x.png',
          info: '号卡电商企业',
        },
        {
          isShow: true,
          title: '将自有渠道接入超级号店，全民帮你分销号卡。共享共销，扩大销售渠道，持续走量。',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_channel@2x.png',
          iconb: 'https://tgjhimg.xiaoj.com/image/pc/icon_channel_b.png',
          info: '号卡渠道商',
        },
        {
          isShow: true,
          title: '让你的用户购卡、销卡，让超级号店成为流量变现新通道。',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_flow@2x.png',
          iconb: 'https://tgjhimg.xiaoj.com/image/pc/icon_flow_b@2x.png',
          info: '私域流量主',
        },
        {
          isShow: true,
          title: '0成本、0囤货、0风险副业创业。只需空闲的时间将号卡推给有需要好友，就能获得丰厚的佣金。',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_job@2x.png',
          iconb: 'https://tgjhimg.xiaoj.com/image/pc/icon_job_b@2x.png',
          info: '个人兼职',
        },
      ],
      helpLisp: [
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_development.png',
          title: '研发资历成熟，系统由前百度产品及技术架构师打造，久经考验值得信赖。',
        },
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_cooperation .png',
          title: '公司及合作伙伴拥有7年号卡行业积累，接触号卡售卖市场，更了解号卡生意。',
        },
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_application .png',
          title: 'SAAS企业级应用，让更多人低成本、低消耗做号卡生意，提升业绩、流量变现。',
        },
        {
          imgUrl: 'https://tgjhimg.xiaoj.com/image/pc/icon_service.png',
          title: '提供一站式服务，协助解决开店相关的公众号、小程序、支付等问题。',
        },
      ],
      timer: null,
    };
  },
  created() {
    const that = this;
    that.timer = setInterval(function() {
      console.log(document.readyState);
      if (document.readyState === 'complete') {
        that.spinShow = false;
        window.clearInterval(that.timer);
      }
    }, 1000);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleCode() {
      this.codeShow = !this.codeShow;
    },
    // 监听页面滚动
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 50) {
        this.scrollValue = true;
        this.fontColor = '#000000';
        this.btnColor = '#ffffff';
      } else {
        this.scrollValue = false;
        this.fontColor = '#ffffff';
        this.btnColor = '';
      }
      // scrollTop > 145 ? this.scrollValue = true : this.scrollValue = false
      // console.log(this.scrollValue)
    },
    closeCode(ev) {
      if (!this.$refs.codeSh.contains(ev.target)) {
        this.codeShow = false;
      }
    },
    mouseOver(index) {
      // console.log(index)
      this.moreList[index].isShow = !this.moreList[index].isShow;
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.home {
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  margin: auto;
  background: rgb(246, 247, 249);
  .home-top {
    width: 100%;

    .isfixed {
      width: 100vw;
      height: 61px;
      position: fixed;
      top: 0;
      z-index: 999;
      background-color: white;
      border-bottom: 1px solid rgb(233, 232, 232);
    }

    .top-carousel {
      height: 579px;
      background-image: url('https://tgjhimg.xiaoj.com/image/pc/banner1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .carousel-item {
        padding-top: 205px;
        padding-left: 20vw;
        .item1 {
          font-size: 40px;
          color: white;
        }

        .item-box {
          margin-top: 31px;
          height: 36px;
          line-height: 36px;

          .item2 {
            font-size: 18px;
            padding-left: 14px;
            color: white;
          }
        }

        .item-btn {
          margin-top: 70px;
          margin-left: 3px;

          .ivu-btn {
            background: rgb(85, 155, 255);
            width: 146px;
            height: 43px;
            color: white;
            border: 0;

            &:hover {
              background: rgb(62, 133, 231);
              color: rgb(233, 232, 232);
            }
          }
        }
      }
    }
    .top-carousel2 {
      height: 579px;
      background-image: url('https://tgjhimg.xiaoj.com/image/pc/banner2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .carousel-item {
        padding-top: 205px;
        padding-left: 20vw;
        .item1 {
          font-size: 40px;
          color: white;
        }

        .item-box {
          margin-top: 31px;
          height: 36px;
          line-height: 36px;

          .item2 {
            font-size: 18px;
            padding-left: 14px;
            color: white;
          }
        }

        .item-btn {
          margin-top: 70px;
          margin-left: 3px;

          .ivu-btn {
            background: rgb(85, 155, 255);
            width: 146px;
            height: 43px;
            color: white;
            border: 0;

            &:hover {
              background: rgb(62, 133, 231);
              color: rgb(233, 232, 232);
            }
          }
        }
      }
    }
    .top-carousel3 {
      height: 579px;
      background-image: url('https://tgjhimg.xiaoj.com/image/pc/banner3.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .carousel-item {
        padding-top: 205px;
        padding-left: 20vw;
        .item1 {
          font-size: 40px;
          color: white;
        }

        .item-box {
          margin-top: 31px;
          height: 36px;
          line-height: 36px;

          .item2 {
            font-size: 18px;
            padding-left: 14px;
            color: white;
          }
        }

        .item-btn {
          margin-top: 70px;
          margin-left: 3px;

          .ivu-btn {
            background: rgb(85, 155, 255);
            width: 146px;
            height: 43px;
            color: white;
            border: 0;

            &:hover {
              background: rgb(62, 133, 231);
              color: rgb(233, 232, 232);
            }
          }
        }
      }
    }
    .top-menu {
      width: 100%;
      position: absolute;
      top: 45px;
    }
  }
  .center-title {
    background-color: white;

    .title-item-box {
      background: rgb(249, 250, 251);
      display: flex;
      align-items: center;
      justify-content: center;

      .title-item {
        height: 100px;
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(226, 225, 225);
        border-left: 1px solid rgb(226, 225, 225);

        .icon1 {
          display: inline-block;
          width: 40px;
          height: 41px;
          background: url('https://tgjhimg.xiaoj.com/image/pc/icon_Sale@2x.png') no-repeat center;
          background-size: contain;
        }
        .icon2 {
          display: inline-block;
          width: 40px;
          height: 41px;
          background: url('https://tgjhimg.xiaoj.com/image/pc/icon_distribution@2x.png') no-repeat center;
          background-size: contain;
        }
        .icon3 {
          display: inline-block;
          width: 40px;
          height: 41px;
          background: url('https://tgjhimg.xiaoj.com/image/pc/icon_Mall@2x.png') no-repeat center;
          background-size: contain;
        }
        .item-font {
          margin: 0 20px;

          .font-title {
            font-size: 16px;
          }
          .font-text {
            color: #9b9ba3;
          }
        }
        &:hover {
          background: rgb(241, 243, 243);
        }
      }
    }

    .title-super {
      height: 150px;
      line-height: 200px;
      font-size: 36px;
      text-align: center;
    }

    .title-describe {
      text-align: center;
      font-size: 16px;

      & span:nth-child(2) {
        color: #02a7f0;
      }
    }
  }

  .home-tab {
    background: white;
    padding-top: 35px;
    padding-bottom: 60px;
  }

  .home-info {
    background: url('https://tgjhimg.xiaoj.com/image/pc/bg.png') no-repeat center;
    background-size: cover;
    // background: linear-gradient(
    //   180deg,
    //   rgb(59, 59, 59) 0%,
    //   rgb(90, 46, 107) 100%
    // );

    .info-title {
      height: 167px;
      line-height: 167px;
      font-size: 36px;
      text-align: center;
      color: white;
    }

    .info-more {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 206px;

      .more-top {
        width: 158px;
        height: 158px;
        color: white;
        margin-right: 100px;
        text-align: center;
        background: transparent;
        position: relative;
        top: -50px;
        transition: all 0.5s ease;

        &:hover img {
          transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
          animation: 0.5s ease 0s 1 normal none running gelatine;
          cursor: pointer;
        }
        &:hover .more-top-diamond {
          transform: scale(1.3);
          opacity: 1;
          cursor: pointer;
        }
        &:hover .more-top-diamond2 {
          animation: 0.8s ease diamondd;
          animation-fill-mode: forwards;
          visibility: visible;
          cursor: pointer;
        }
        &:hover .bottom-title {
          cursor: pointer;
          color: black !important;
        }
        .more-top-diamond {
          opacity: 0;
          width: 158px;
          height: 158px;
          position: absolute;
          left: 30%;
          top: 50%;
          transition: all 1s ease;
          background: transparent;

          &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: -104px;
            border-width: 74px;
            border-style: solid;
            border-color: transparent transparent rgb(240, 246, 255);
            width: 0;
            height: 0;
          }

          &::after {
            content: '';
            position: absolute;
            left: 0px;
            top: 43px;
            width: 0;
            height: 0;
            border-width: 74px;
            border-style: solid;
            border-color: rgb(240, 246, 255) transparent transparent;
          }
        }
        .more-top-diamond2 {
          opacity: 1;
          width: 168px;
          height: 168px;
          visibility: hidden;
          position: absolute;
          left: 23%;
          top: 50%;
          transition: all 1s ease;
          background: transparent;

          &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: -122px;
            border-width: 84px;
            border-style: solid;
            border-color: transparent transparent rgb(240, 246, 255);
            width: 0;
            height: 0;
          }

          &::after {
            content: '';
            position: absolute;
            left: 0px;
            top: 45px;
            width: 0;
            height: 0;
            border-width: 84px;
            border-style: solid;
            border-color: rgb(240, 246, 255) transparent transparent;
          }
        }

        .top-title {
          height: 76px;
        }

        .more-bottom {
          position: absolute;
          left: 35%;
          top: 50%;
          width: 120px;
          .bottom-img {
            margin: 0 auto;
            width: 30px;
            height: 31px;

            img {
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-width: 100%;
            }
          }
          .bottom-title {
            color: white;
            font-size: 20px;
          }
        }
      }
    }

    .info-bottom {
      height: 217px;
      line-height: 150px;
      text-align: center;
      // background-image: url('http://d1icd6shlvmxi6.cloudfront.net/gsc/H2081B/b9/e8/d3/b9e8d3147744430b8ff673255adf38c6/images/官网/u8049.png?token=2cd34b44fb79e561bd781ae3b16bc13fe5e1336fd4b0de7153380bd16c43f0d4');

      .bottom-btn {
        height: 50px;
        width: 155px;
      }
    }
  }
  .home-service {
    height: 590px;

    .service-title {
      text-align: center;

      .title-top {
        padding-top: 75px;
        font-size: 36px;
      }

      .title-bottom {
        font-size: 16px;
        color: #7f7f7f;
      }
    }

    .service-help {
      display: flex;
      align-items: center;
      justify-content: center;

      .help-box {
        background: white;
        width: 235px;
        height: 308px;
        margin: 30px 20px;

        .help-box-img {
          margin: 0 10px;
          padding: 10px;
          width: 185px;
          height: 180px;
          text-align: center;

          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .help-box-title {
          margin: 0 20px 30px 20px;
        }

        &:hover {
          margin-top: 0;
          transition: all 0.5s ease;
          box-shadow: 0 5px 10px gray;
        }
      }
    }
  }
  .home-voice {
    background: white;

    .voice-title {
      text-align: center;

      .voice-title-top {
        font-size: 36px;
        padding-top: 90px;
      }

      .voice-title-bottom {
        padding-top: 5px;
        font-size: 16px;
        color: #7f7f7f;
      }
    }

    .voice-carousel-box {
      background: url('https://tgjhimg.xiaoj.com/image/pc/comma-left.png') no-repeat top left,
        url('https://tgjhimg.xiaoj.com/image/pc/comma-right.png') no-repeat bottom right;
      background-position: 20% 0, 80% 50%;
      background-size: 60px 60px, 60px 60px;
      .voice-title-carousel {
        margin: auto;
        background-color: transparent;
        width: 1166px;
        height: 252px;

        .voice-carousel {
          margin: 50px 300px;
          display: flex;
          .voice-carousel-img img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }

          .voice-carousel-detail {
            margin-left: 50px;

            .detail-left {
              font-size: 20px;
              margin-right: 20px;
              font-weight: bold;
              color: #222;
            }

            .detail-right {
              font-size: 16px;
              color: #7f7f7f;
            }

            .detail-text {
              margin-left: -15px;
              margin-top: 10px;
              font-size: 16px;
              color: #444;
            }
          }
        }
      }
    }
  }

  .home-lgo {
    height: 217px;
    background: rgb(85, 155, 255);
    text-align: center;

    .home-lgo-title {
      padding-top: 35px;
      font-size: 28px;
      color: white;
    }

    .home-lgo-button {
      width: 155px;
      height: 42px;
      color: #0060f4;
      margin-top: 25px;

      &:hover {
        background: rgb(240, 238, 238);
        color: #256dd8;
      }
    }
  }

  .home-footer {
    display: flex;
    justify-content: center;
    height: 360px;
    background-color: #02204b;

    .footer-left {
      display: flex;
      a:hover {
        color: #0060f4;
      }
      dl {
        padding-left: 120px;
        padding-top: 75px;
      }
      dt {
        padding-bottom: 15px;
        a {
          font-size: 16px;
          color: white;
        }
      }
      dd {
        padding-top: 20px;
        a {
          color: white;
        }
      }
    }

    .footer-content {
      dl {
        padding-left: 120px;
        padding-top: 75px;
      }
      dt {
        padding-bottom: 15px;

        a {
          font-size: 16px;
          color: white;

          &:hover {
            color: #0060f4;
          }
        }
      }
      dd a {
        display: flex;
        color: white;
      }
      dd {
        align-content: center;
        width: 204px;
        color: white;
        padding-top: 20px;
        .footer-content-img {
          width: 48px;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }

        a div:hover {
          color: #0060f4;
        }
      }
    }

    .footer-right {
      display: flex;
      padding-top: 110px;
      padding-left: 120px;

      .right-imgbox {
        padding-right: 30px;
        color: white;
        img {
          width: 95px;
          height: 94px;
        }
      }
    }
  }
  .footer-copyright {
    display: block;
    background-color: #02204b;
    padding-bottom: 50px;
    hr {
      width: 80%;
      margin: 0 auto;
      border-color: #797878;
      opacity: 0.3;
    }
    .footer-copyright-font {
      width: 80%;
      color: rgb(228, 226, 226);
      padding-top: 20px;
      margin: 0 auto;
      .font-right {
        float: right;
        a {
          color: rgb(228, 226, 226);
        }
      }
    }
  }
  .home-float {
    .float-backtop {
      .float-customer-box {
        position: fixed;
        right: 30px;
        width: 60px;
        height: 60px;
        border-radius: 50%;

        img {
          height: 100%;
          width: 100%;
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          cursor: pointer;
        }
      }
      .float-backtop-box {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0 0 10px rgb(240, 239, 239);

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .code-mask {
    width: 100%;
    height: 100%;
    z-index: 1002;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .home-code {
      position: fixed;
      top: 10vh;
      left: 35vw;
      height: 100%;
      text-align: center;

      .code-img {
        width: 350px;

        img {
          max-width: 100%;
          max-height: 100%;
          box-shadow: 0px 3px 68px 0px rgba(87, 130, 220, 0.1);
        }
      }
    }
  }

  @keyframes gelatine {
    0%,
    100% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
  @keyframes diamondd {
    0% {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1400px) {
  .carousel-item {
    padding-top: 205px;
    padding-left: 10vw !important;
  }
}

@media screen and(max-width: 720px) {
  .top-carousel-box {
    position: relative;

    .top-carousel {
      height: 200px !important;
      background-image: url('https://tgjhimg.xiaoj.com/image/pc/banner1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .carousel-item {
        margin-top: -380px;
        padding-left: 5vw !important;
        .item1 {
          font-size: 16px !important;
        }

        .item-box {
          margin-top: 31px;
          height: 36px;
          line-height: 20px !important;

          .item2 {
            font-size: 14px !important;
            padding-left: 14px;
            color: white;
          }
        }

        .item-btn {
          margin-top: 10px !important;

          .ivu-btn {
            width: 110px !important;
            height: 30px !important;
            background: rgb(85, 155, 255);
            width: 146px;
            height: 43px;
            color: white;
            border: 0;

            &:hover {
              background: rgb(62, 133, 231);
              color: rgb(233, 232, 232);
            }
          }
        }
      }
    }

    .top-carousel2 {
      height: 200px !important;
      background-image: url('https://tgjhimg.xiaoj.com/image/pc/banner2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .carousel-item {
        padding-top: 35px !important;
        .item1 {
          font-size: 16px !important;
          color: white;
        }

        .item-box {
          line-height: 20px !important;

          .item2 {
            font-size: 14px !important;
            padding-left: 14px;
            color: white;
          }
        }

        .item-btn {
          margin-top: 10px !important;
          margin-left: 5vw !important;

          .ivu-btn {
            width: 110px !important;
            height: 30px !important;

            &:hover {
              background: rgb(62, 133, 231);
              color: rgb(233, 232, 232);
            }
          }
        }
      }
    }
  }

  .top-carousel3 {
    height: 200px !important;
    background-image: url('https://tgjhimg.xiaoj.com/image/pc/banner3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .carousel-item {
      padding-top: 35px !important;
      padding-left: 15vw !important;
      .item1 {
        font-size: 16px !important;
        padding-left: 15vw !important;
      }

      .item-box {
        line-height: 20px !important;

        .item2 {
          font-size: 14px !important;
        }
      }

      .item-btn {
        margin-top: 10px !important;
        margin-left: 15vw !important;

        .ivu-btn {
          width: 110px !important;
          height: 30px !important;

          &:hover {
            background: rgb(62, 133, 231);
            color: rgb(233, 232, 232);
          }
        }
      }
    }
  }
  .center-title {
    .title-item-box {
      background: rgb(249, 250, 251);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: wrap;
    }
  }

  .title-super {
    line-height: 60px !important;
    font-size: 36px;
    text-align: center;
  }

  .home-info {
    height: 100vh !important;
    background: linear-gradient(180deg, rgba(70, 144, 255, 1) 0%, rgba(80, 151, 255, 1) 100%);
    .info-title {
      line-height: 60px !important;
      font-size: 36px;
      text-align: center;
      color: white;
    }

    .info-more {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 206px;

      .more-top {
        width: 168px;
        color: white;
        margin-right: 10px !important;

        .top-title {
          height: 76px;
        }

        // .more-bottom {
        //   .bottom-title {
        //     color: white;
        //     font-size: 20px;
        //     padding-bottom: 20px;
        //   }
        // }
      }
    }
    .info-bottom {
      height: 217px;
      line-height: 150px;
      text-align: center;
      // background-image: url('http://d1icd6shlvmxi6.cloudfront.net/gsc/H2081B/b9/e8/d3/b9e8d3147744430b8ff673255adf38c6/images/官网/u8049.png?token=2cd34b44fb79e561bd781ae3b16bc13fe5e1336fd4b0de7153380bd16c43f0d4');

      .bottom-btn {
        height: 50px;
        width: 155px;
        margin-top: 220px;
      }
    }
  }
  .service-help {
    display: flex;
    align-items: center;
    justify-content: center;

    .help-box {
      background: white;
      width: 235px;
      height: 308px;
      margin: 30px 20px;

      .help-box-img {
        text-align: center;

        img {
          width: 200px;
          height: 160px;
        }
      }

      .help-box-title {
        margin: 0 20px 30px 20px;
      }

      &:hover {
        margin-top: 0;
        transition: all 0.5s ease;
        box-shadow: 0 5px 10px gray;
      }
    }
  }
  .home-service {
    height: 590px;

    .service-title {
      text-align: center;

      .title-top {
        padding-top: 75px;
        font-size: 36px;
      }

      .title-bottom {
        font-size: 16px;
        color: #7f7f7f;
      }
    }

    .service-help {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: wrap;

      .help-box {
        background: white;
        width: 235px;
        height: 285px !important;
        margin: 10px 20px !important;

        .help-box-img {
          text-align: center;

          img {
            width: 200px;
            height: 160px;
          }
        }

        .help-box-title {
          margin: 0 20px 10px 20px !important;
        }

        &:hover {
          margin-top: 0;
          transition: all 0.5s ease;
          box-shadow: 0 5px 10px gray;
        }
      }
    }
  }
  .home-voice {
    margin-top: 780px;
    background: white;

    .voice-title {
      text-align: center;

      .voice-title-top {
        font-size: 36px;
        padding-top: 90px;
      }

      .voice-title-bottom {
        padding-top: 5px;
        font-size: 16px;
        color: #7f7f7f;
      }
    }

    .voice-carousel-box {
      background: url('https://tgjhimg.xiaoj.com/image/pc/comma-left.png') no-repeat top left,
        url('https://tgjhimg.xiaoj.com/image/pc/comma-right.png') no-repeat bottom right;
      background-position: 20% 0, 80% 50%;
      background-size: 60px 60px, 60px 60px;
      .voice-title-carousel {
        margin: auto;
        background-color: transparent;
        width: 100% !important;
        height: 252px;

        .voice-carousel {
          margin: 50px 0px !important;
          display: flex;
          width: 100%;
          .voice-carousel-img img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }

          .voice-carousel-detail {
            margin-left: 50px;

            .detail-left {
              font-size: 20px;
              margin-right: 20px;
              font-weight: bold;
              color: #222;
            }

            .detail-right {
              font-size: 16px;
              color: #444;
            }

            .detail-text {
              margin-left: -15px;
              margin-top: 10px;
              font-size: 16px;
              color: #444;
              word-wrap: break-word !important;
            }
          }
        }
      }
    }
  }
  .home-footer {
    height: 100% !important;
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    height: 360px;
    background-color: #02204b;

    .footer-left {
      display: flex;
      flex-flow: wrap;
      a:hover {
        color: #0060f4;
      }
      dl {
        padding-left: 30px !important;
        padding-top: 75px;
      }
      dt {
        padding-bottom: 15px;
        a {
          font-size: 16px;
          color: white;
        }
      }
      dd {
        padding-top: 20px;
        a {
          color: white;
        }
      }
    }

    .footer-content {
      dl {
        padding-left: 0 !important;
        padding-top: 75px;
      }
      dt {
        padding-bottom: 15px;

        a {
          font-size: 16px;
          color: white;

          &:hover {
            color: #0060f4;
          }
        }
      }
      dd a {
        display: flex;
        color: white;
      }
      dd {
        align-content: center;
        width: 204px;
        color: white;
        padding-top: 20px;

        img {
          width: 48px;
          height: 49px;
          margin-right: 10px;
        }
        a div:hover {
          color: #0060f4;
        }
      }
    }

    .footer-right {
      display: flex;
      padding-top: 110px;
      padding-left: 10px !important;

      .right-imgbox {
        padding-right: 30px;
        color: white;
        img {
          width: 95px;
          height: 94px;
        }
      }
    }
  }
  .home-top {
    width: 100%;

    .isfixed {
      display: none;
      height: 61px;
      position: fixed;
      top: 0;
      z-index: 999;
      background-color: white;
      border-bottom: 1px solid rgb(182, 180, 180);
    }
    .top-carousel {
      height: 579px;
      width: 100% !important;
      background-image: url('https://tgjhimg.xiaoj.com/image/pc/banner1.png');
      background-repeat: no-repeat;
      background-size: cover;

      .carousel-item {
        position: absolute;
        left: 2vw !important;
        top: 205px;
        width: 300px;
        text-align: center;
        .item1 {
          font-size: 40px;
          word-wrap: break-word;
          color: white;
        }

        .item-box {
          margin-top: 31px;
          height: 36px;
          line-height: 36px;

          .item2 {
            font-size: 18px;
            padding-left: 14px;
            color: white;
          }
        }

        .item-btn {
          margin-top: 70px;
          margin-left: 3px;

          .ivu-btn {
            background: rgb(85, 155, 255);
            width: 146px;
            height: 43px;
            color: white;
            border: 0;

            &:hover {
              background: rgb(62, 133, 231);
              color: rgb(233, 232, 232);
            }
          }
        }
      }
    }
  }
  .home-code {
    position: fixed;
    z-index: 1002;
    top: 10vh;
    left: 10vw !important;
    height: 100%;
    text-align: center;

    .code-img {
      width: 300px !important;

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 20px 20px 0 0;
      }
    }
  }
}
.code-enter {
  transform: translateY(-41px);
}
.code-leave-to {
  transform: translateY(0);
}

.code-enter-active {
  transition: all 0.8s ease;
}
.codeimg-enter,
.codeimg-leave-active {
  opacity: 0;
}
.codeimg-enter-to,
.codeimg-leave {
  opacity: 1;
}
.codeimg-enter-active,
.codeimg-leave-active {
  transition: all 0.8s ease;
}
.codeimgbox-leave-active {
  transition: all 0.6s ease;
  transition-delay: 0.1s;
}
/deep/ .ivu-modal-body {
  padding: 0;
}
/deep/ .ivu-modal-footer {
  text-align: center;
}
</style>
