<template>
  <div class="home">
    <div class="home-menu">
      <mobileMenu />
    </div>
    <div class="home-carousel">
      <Carousel v-model="carouselValue" loop :autoplay="true" class="home-carousel-box" :autoplay-speed="5000">
        <CarouselItem>
          <div class="home-home-carousel-box-img">
            <img src="https://tgjhimg.xiaoj.com/image/pc/banner1.png" />
          </div>
          <div class="home-home-carousel-box-title">
            实现号卡生意“管理+营销”全流程
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="home-home-carousel-box-img">
            <img src="https://tgjhimg.xiaoj.com/image/pc/banner2.png" />
          </div>
          <div class="home-home-carousel-box-title">
            共建号码“共享共销”新模式
          </div>
        </CarouselItem>
        <CarouselItem>
          <div class="home-home-carousel-box-img">
            <img src="https://tgjhimg.xiaoj.com/image/pc/banner3.png" />
          </div>
          <div class="home-home-carousel-box-title">
            社交电商号卡系统
          </div>
        </CarouselItem>
      </Carousel>
    </div>
    <div class="home-scrolllist">
      <div class="home-scrolllist-list" v-for="(item, index) in scrollList" :key="index">
        <div class="home-scrolllist-list-img">
          <img :src="item.icon" />
        </div>
        <div class="home-scrolllist-list-item">
          <div>
            {{ item.Ttitle }}
          </div>
          <div class="home-scrolllist-list-item-right">
            {{ item.Btitle }}
          </div>
        </div>
      </div>
    </div>
    <mobile-tabs></mobile-tabs>
    <mobile-more-scroll @handleCode="handleCode"></mobile-more-scroll>
    <mobile-service></mobile-service>
    <div class="home-voice">
      <div class="home-voice-title">
        <h2>
          来自客户的心声
        </h2>
        <!-- <h3>
          3000+卡商已经行动起来了，未来可覆盖不同行业的自媒体人、UP主、直播主
        </h3> -->
      </div>
      <div class="home-voice-carousel">
        <Carousel
          v-model="carouseVoice"
          loop
          :autoplay="true"
          :autoplay-speed="5000"
          dots="none"
          class="home-voice-carousel-box"
        >
          <CarouselItem>
            <div class="home-voice-carousel-box-title">
              <div class="home-voice-carousel-box-title-img">
                <img src="https://tgjhimg.xiaoj.com/image/pc/icon_li.png" />
                <div class="home-voice-carousel-box-title-detail">
                  <span class="home-voice-carousel-box-title-detail-left">
                    李总
                  </span>
                  <span class="home-voice-carousel-box-title-detail-right">
                    小巨网络小巨靓号项目总经理
                  </span>
                </div>
              </div>

              <div class="home-voice-carousel-box-title-detail-text">
                已经用超级号店的号码电商系统7年了，从粗放的号码管理到现在的丰富功能，一直为公司创造更大的价值保驾护航。点赞！
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="home-voice-carousel-box-title">
              <div class="home-voice-carousel-box-title-img">
                <img src="https://tgjhimg.xiaoj.com/image/pc/icon_lin.png" />
                <div class="home-voice-carousel-box-title-detail">
                  <span class="home-voice-carousel-box-title-detail-left">
                    林总
                  </span>
                  <span class="home-voice-carousel-box-title-detail-right">
                    XX靓号总经理
                  </span>
                </div>
              </div>
              <div class="home-voice-carousel-box-title-detail-text">
                超级号店利用技术手段帮公司对接了移动花卡、王卡、宝藏卡等通道，同时我们也实现了号卡销售全面实现了线上化，销售提升了，管理也更方便了。
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
    <div class="home-lgo">
      <div class="home-lgo-title">
        让我们一起,进入号卡新营销时代
      </div>
      <Button class="home-lgo-button" shape="circle" @click="handleCode">
        申请免费试用
      </Button>
    </div>
    <mobile-footer></mobile-footer>
    <div class="home-float">
      <div class="float-backtop">
        <Affix :offset-bottom="200">
          <div class="float-customer-box" @click="handleCode">
            <img src="https://tgjhimg.xiaoj.com/image/pc/icon_customer.png" />
          </div>
        </Affix>
      </div>
      <div class="float-backtop">
        <BackTop :height="100" :bottom="100">
          <div class="float-backtop-box">
            <img src="https://tgjhimg.xiaoj.com/image/pc/icon_arrow.png" />
          </div>
        </BackTop>
      </div>
    </div>
    <transition name="codeimgbox">
      <div class="code-mask" v-show="codeShow" @click="closeCode($event)">
        <transition name="codeimg">
          <div v-show="codeShow" class="home-code" ref="codeSh">
            <div class="code-img">
              <img src="https://tgjhimg.xiaoj.com/image/pc/code_yxiao.png" />
            </div>
            <div class="code-close">
              <Icon type="md-close-circle" @click="handleCode" size="50" color="white" />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import mobileMenu from '@/components/mobileMenu.vue';
import mobileMoreScroll from '@/components/mobileMoreScroll.vue';
import mobileService from '@/components/mobileService.vue';
import mobileTabs from '@/components/mobileTabs.vue';
import mobileFooter from '@/components/mobileFooter.vue';
export default {
  name: 'homeMobilePage',
  components: {
    mobileMenu,
    mobileMoreScroll,
    mobileService,
    mobileTabs,
    mobileFooter,
  },
  metaInfo: {
    title: '超级号店',
    meta: [
      {
        name: 'keyword',
        content:
          '靓号销售平台,手机靓号交易,定制号码购买,企业靓号服务,号码拍卖系统,高端号码市场,号码资源管理,虚拟号码服务,号码交易安全,号码选购指南',
      },
      {
        name: 'description',
        content:
          '超级号店 - 您的专业靓号销售SaaS平台。我们提供全面的手机靓号交易服务，包括定制号码购买、企业靓号解决方案和安全高效的号码拍卖系统。加入我们，享受高端号码市场的便利与安全，让您的通讯标识更加独特和专业。',
      },
    ],
  },
  data() {
    return {
      carouselValue: 0,
      carouseVoice: 0,
      codeShow: false,
      scrollList: [
        {
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_Sale@2x.png',
          Ttitle: '号卡售卖系统',
          Btitle: '三大运营商号卡全方位营销',
        },
        {
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_distribution@2x.png',
          Ttitle: '号卡分销系统',
          Btitle: '二级分销，引爆朋友圈蓝海',
        },
        {
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_Mall@2x.png',
          Ttitle: '靓号商城系统',
          Btitle: '高效低成本实现号码销售新增长',
        },
      ],
    };
  },
  methods: {
    handleCode() {
      this.codeShow = !this.codeShow;
    },
    closeCode(ev) {
      if (!this.$refs.codeSh.contains(ev.target)) {
        this.codeShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: white;
  }
  .home-carousel-box {
    margin-top: 3.2rem;

    .home-home-carousel-box-img {
      height: 11rem;
    }
    .home-home-carousel-box-title {
      margin-top: -6.25rem;
      margin-left: 15vw;
      height: 6rem;
      font-size: 1rem;
      color: white;
      font-weight: 800;
    }
    .home-carousel-box-font {
      z-index: 999;
      position: absolute;
      top: 20;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .home-scrolllist {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    .home-scrolllist-list {
      // margin-right: 1rem;
      display: flex;
      align-items: center;
      width: 100%;
      .home-scrolllist-list-img {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.8rem;
        img {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      .home-scrolllist-list-item {
        width: 10rem;
        border-right: 1px solid rgba(206, 206, 206, 0.5);
        .home-scrolllist-list-item-right {
          font-size: 0.75rem;
          color: #9b9ba3;
        }
      }
    }
  }
  .home-voice {
    .home-voice-title {
      padding-top: 3rem;
      text-align: center;
      h2 {
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: 600px;
      }
      h3 {
        padding-top: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 0.75rem;
        color: #9c9eae;
        line-height: 1.3rem;
        font-weight: 400;
      }
    }
    .home-voice-carousel {
      background: url('https://tgjhimg.xiaoj.com/image/pc/comma-left.png') no-repeat top left,
        url('https://tgjhimg.xiaoj.com/image/pc/comma-right.png') no-repeat bottom right;
      background-position: 20% 0, 80% 50%;
      background-size: 30px 30px, 30px 30px;
      .home-voice-carousel-box {
        margin: auto;
        background: transparent;
        width: 95%;
        .home-voice-carousel-box-title {
          width: 100%;
          margin: 3.125rem 0;
          .home-voice-carousel-box-title-img {
            display: flex;
            align-items: center;
            width: 100%;
            border-radius: 50%;
            img {
              width: 2.5rem;
              margin-left: 1rem;
            }
          }
          .home-voice-carousel-box-title-detail {
            margin-left: 1.5rem;
            .home-voice-carousel-box-title-detail-left {
              font-size: 1.25rem;
              // margin-right: 20px;
              font-weight: bold;
              color: #222;
            }
            .home-voice-carousel-box-title-detail-right {
              font-size: 1rem;
              color: #7f7f7f;
            }
          }
          .home-voice-carousel-box-title-detail-text {
            margin-top: 0.625rem;
            padding-left: 1rem;
            padding-right: 0.5rem;
            font-size: 0.8rem;
            color: #444;
          }
        }
      }
    }
  }
  .home-lgo {
    height: 11rem;
    background: rgb(85, 155, 255);
    text-align: center;
    padding-bottom: 1.25rem;

    .home-lgo-title {
      padding-top: 2.5rem;
      font-size: 1.5rem;
      color: white;
    }
    .home-lgo-button {
      margin-top: 1.5625rem;
    }
  }
  .home-float {
    .float-backtop {
      .float-customer-box {
        position: fixed;
        right: 30px;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          cursor: pointer;
        }
      }
      .float-backtop-box {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0 0 10px rgb(240, 239, 239);

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .code-mask {
    width: 100%;
    height: 100%;
    z-index: 1002;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .home-code {
      position: fixed;
      top: 10vh;
      left: 10vw;

      text-align: center;

      .code-img {
        width: 300px;

        img {
          max-width: 100%;
          max-height: 100%;
          box-shadow: 0px 3px 68px 0px rgba(87, 130, 220, 0.1);
        }
      }
    }
  }
}
.codeimg-enter,
.codeimg-leave-active {
  opacity: 0;
}
.codeimg-enter-to,
.codeimg-leave {
  opacity: 1;
}
.codeimg-enter-active,
.codeimg-leave-active {
  transition: all 0.8s ease;
}
.codeimgbox-leave-active {
  transition: all 0.5s ease;
  transition-delay: 0.1s;
}
</style>
