<template>
  <div class="more-scroll">
    <div class="more-scroll-title">
      多场景使用，一切以增长为目的
    </div>
    <div class="more-scroll-box">
      <div
        class="more-scroll-box-list"
        v-for="(item,index) in moreList"
        :key="index"
      >
        <!-- <div class="more-scroll-box-list-title">
          {{ item.title }}
        </div> -->
        <div>
          <img src="https://tgjhimg.xiaoj.com/image/pc/img_line.png">
        </div>
        <div class="more-scroll-box-list-icon">
          <img :src="item.icon">
        </div>
        <div class="more-scroll-box-list-info">
          {{ item.info }}
        </div>
      </div>
    </div>
    <div class="more-scroll-box-button">
      <Button @click="handleCode">
        申请免费试用
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileMoreScroll',
  data () {
    return {
      moreList: [
        {
          // title: '免安装，免部署使用超级号店快速构建号卡销售系统，实现运营数字化、营销自动化、管理精细化',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_retailers@2x.png',
          info: '号卡电商企业'
        },
        {
          // title: '将自有渠道接入超级号店，全民帮你分销号卡。共享共销，扩大销售渠道，持续走量。',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_channel@2x.png',
          info: '号卡渠道商'
        },
        {
          // title: '让你的用户购卡、销卡，让超级号店成为流量变现新通道。',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_flow@2x.png',
          info: '私域流量主'
        },
        {
          // title: '0成本、0囤货、0风险副业创业。只需空闲的时间将号卡推给有需要好友，就能获得丰厚的佣金。',
          icon: 'https://tgjhimg.xiaoj.com/image/pc/icon_job@2x.png',
          info: '个人兼职'
        }
      ]
    }
  },
  methods: {
    handleCode () {
      this.$emit('handleCode')
    }
  }
}
</script>

<style lang="scss" scoped>
.more-scroll {
  padding-top: 3rem;
  padding-bottom: 2rem;
  background: url('https://tgjhimg.xiaoj.com/image/pc/bg.png') no-repeat center;
  background-size: cover;
  .more-scroll-title {
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-weight: 600;
    // margin: 2.5rem auto 0.4375rem;
    padding-bottom: 1rem;
    text-align: center;
    color: white;
  }
  .more-scroll-box {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0 1rem;

    .more-scroll-box-list {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin-right: 1rem;
      padding-bottom: 1rem;

      .more-scroll-box-list-title {
        width: 10rem;
        height: 4rem;
        font-size: 0.75rem;
        margin-top: 0.8rem;
        color: white;
      }

      .more-scroll-box-list-info {
        font-size: 0.82rem;
        line-height: 0.9rem;
        color: white;
        font-weight: 600;
      }
    }
  }
  .more-scroll-box-button {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>